import * as React from "react";
import {
    TextInput,
    EditProps,
    Edit,
    SimpleForm,
    SelectArrayInput,
    Toolbar,
    SaveButton,
    useRedirect,
    useNotify,
    useRefresh,
} from "react-admin";
import {
    ManyToManyReferenceContextProvider,
    ReferenceManyToManyInput,
} from "ra-relationships";

const DeviceEditToolbar = (props: any) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
};

const DeviceEditTitle = ({ record }: any) => {
    return <span>Edit Device: {record ? `${record.email}` : ""}</span>;
};

export const DeviceEdit = (props: EditProps) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = (response: any) => {
        notify(`Device ${response.data.email} saved!`);
        redirect("/devices");
        refresh();
    };
    return (
        <Edit {...props} title={<DeviceEditTitle />} onSuccess={onSuccess}>
            <ManyToManyReferenceContextProvider>
                <SimpleForm toolbar={<DeviceEditToolbar />}>
                    <TextInput disabled source="id" />
                    <TextInput source="friendlyName" />
                    <ReferenceManyToManyInput
                        source="id"
                        reference="users"
                        through="junction_user_device"
                        using="deviceUid,userUid"
                        fullWidth
                        label="Users"
                    >
                        <SelectArrayInput
                            optionText={(choice: any) => {
                                return `${choice.id} - ${choice.email}`;
                            }}
                        />
                    </ReferenceManyToManyInput>
                </SimpleForm>
            </ManyToManyReferenceContextProvider>
        </Edit>
    );
};
