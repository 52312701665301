import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    ListProps,
    ShowButton,
    EditButton,
} from "react-admin";

const UserFilter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput label="Search by email" source="email" alwaysOn />
        </Filter>
    );
};

export const UserList = (props: ListProps) => {
    return (
        <List {...props} filters={<UserFilter />}>
            <Datagrid rowClick="edit">
                <TextField source="email" />
                <ShowButton />
                <EditButton basePath="/users" />
            </Datagrid>
        </List>
    );
};
