import * as React from "react";
import {
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    ShowProps,
} from "react-admin";
import { ReferenceManyToManyField } from "ra-relationships";
import { makeStyles } from "@material-ui/core";

const UserShowTitle = ({ record }: any) => {
    return <span>View User: {record ? `${record.email}` : ""}</span>;
};

const deviceRowStyle = (record: any, index: any) => ({
    backgroundColor: index % 2 === 0 ? "white" : "white",
});

const useStyles = makeStyles({
    header: {
        fontWeight: "bold",
    },
});

export const UserShow = (props: ShowProps) => {
    const classes = useStyles();
    return (
        <Show {...props} title={<UserShowTitle />}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="email" />

                <ReferenceManyToManyField
                    source="id"
                    reference="devices"
                    through="junction_user_device"
                    using="userUid,deviceUid"
                >
                    <Datagrid rowStyle={deviceRowStyle} stickyHeader>
                        <TextField
                            source="id"
                            headerClassName={classes.header}
                        />
                        <TextField
                            source="friendlyName"
                            headerClassName={classes.header}
                        />
                    </Datagrid>
                </ReferenceManyToManyField>
            </SimpleShowLayout>
        </Show>
    );
};
