import React from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import UserIcon from "@material-ui/icons/Group";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
    RAFirebaseOptions,
} from "react-admin-firebase";
import firebase from "firebase";
import { UserList, UserEdit, UserShow } from "./users";
import Dashboard from "./Dashboard";
import { DeviceEdit, DeviceShow, DevicesList } from "./devices";
import CustomLoginPage from "./login/Login";

// eslint-disable-next-line import/extensions
const config = require("./config/firebase.js").firebaseConfig;

firebase.initializeApp(config);

const options: RAFirebaseOptions = {
    logging: true,
    watch: ["users", "devices", "junction_device_users"],
};

const dataProvider2 = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

function App() {
    return (
        <Admin
            authProvider={authProvider}
            dataProvider={dataProvider2}
            loginPage={CustomLoginPage}
            dashboard={Dashboard}
        >
            <Resource
                name="users"
                icon={UserIcon}
                list={UserList}
                show={UserShow}
                edit={UserEdit}
            />
            <Resource
                name="devices"
                icon={DeviceHubIcon}
                list={DevicesList}
                show={DeviceShow}
                edit={DeviceEdit}
            />
            <Resource name="junction_user_device" />
        </Admin>
    );
}

export default App;
